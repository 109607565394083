<template>
  <v-container fluid class="px-0 py-0">
    <v-footer color="#545857">
      <v-row no-gutters class="text-center d-flex flex-column">
        <div class="text-center">
          <!-- <v-btn
            v-for="icon in icons"
            :key="icon"
            :icon="icon"
            class="mx-4"
            variant="text"
          ></v-btn> -->
          <img
            v-for="icon in icons"
            :key="icon"
            @click="openUrl(icon.url)"
            :src="icon.brand"
            class="mx-2"
            :sizes="$vuetify.display.mobile ? 20 : 50"
          />
        </div>
        <div>
          <v-btn
            v-for="link in links"
            :key="link.text"
            class="mx-2"
            color="white"
            rounded="xl"
            variant="text"
            @click="openUrl(link.url)"
          >
            {{ link.text }}
          </v-btn>
        </div>
        <div class="text-center">
          <v-col class="text-center mt-4" cols="12">
            © <strong>Stryker</strong> 1998 - {{ new Date().getFullYear() }}
          </v-col>
        </div>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import linkedin from "../assets/icon-social-t.png";
import facebook from "../assets/icon-social-f.png";

export default {
  name: "FooterComponent",
  data() {
    return {
      linkedin: linkedin,
      facebook: facebook,
      links: [
        {
          text: "CONFIDENTIALITÉ",
          url: "https://www.stryker.com/fr/fr/legal/privacy.html"
        },
        {
          text: "DECLARATION D'ACCESSIBILITÉ",
          url: "https://www.stryker.com/fr/fr/legal/website-accessibility.html"
        },
        {
          text: "AVERTISSEMENTS À L'INTENTION DES PROFESSIONNELS DE SANTÉ",
          url: "https://www.stryker.com/fr/fr/legal/surgeon-disclaimer.html"
        },
        {
          text: "CONDITIONS D'UTILISATION",
          url: "https://www.stryker.com/fr/fr/legal/terms-of-use.html"
        },
        {
          text: "EXPÉRIENCE PRODUIT",
          url: "https://www.stryker.com/productexperience/Legal/Agreement"
        },
        {
          text: "LIGNES D'ASSISTANCE ÉTHIQUE",
          url: "https://app.convercent.com/fr-fr/Anonymous/IssueIntake/LandingPage/b6bb4e84-9fcb-ea11-a974-000d3ab9f296"
        }
      ],
      icons: [
        {
          brand: linkedin,
          url: "https://www.linkedin.com/company/stryker"
        },
        {
          brand: facebook,
          url: "https://www.facebook.com/strykercareers/"
        }
      ]
    };
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    }
  },
  created() {}
};
</script>

<style>
.align-stryker {
  align-content: center;
}
</style>

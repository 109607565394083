import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import { createMetaManager } from "vue-meta";
import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from "./msalConfig";
import VueApexCharts from "vue3-apexcharts";

const initializeMsal = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  return msalInstance;
};

const initializeApp = async () => {
  try {
    const msalInstance = await initializeMsal();
    await msalInstance.handleRedirectPromise();
    app.provide("$msalInstance", msalInstance);
  } catch (error) {
    console.error(error);
  }
};

initializeApp();

loadFonts();

axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axios.defaults.baseURL = process.env.VUE_APP_HOST;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401 || error.response?.status === 419) {
      if (router.currentRoute.name !== "Login") {
        store.state.auth.loggedIn = false;
        store.commit("auth/clearAuthData");
        /* const data = {
          color: "error",
          message: error.response.data.message
        };
        store.commit("showSnackbar", data); */
        router.push({
          name: "login"
        });
      }
    }
    return Promise.reject(error);
  }
);

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueApexCharts)
  .use(createMetaManager());

app.mount("#app");

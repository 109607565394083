<template>
  <v-app-bar color="#ffffff" class="app-bar-custom">
    <v-img
      v-if="$store.state.auth.token == null"
      class="ml-5"
      src="../assets/stryker.png"
      @click="pushViews('home')"
      max-width="150"
    ></v-img>
    <v-spacer v-if="$store.state.auth.token == null"></v-spacer>
    <div class="custom-menu mr-5" v-if="$store.state.auth.token == null">
      <span>CONTACT |</span>
      <span>
        <!-- <v-icon :icon="`mdiSvg:${mdiWeb}`" size="large"></v-icon> -->
        <v-icon :icon="mdiWeb" size="large"></v-icon>FRANCE/FRANÇAIS
      </span>
    </div>

    <template v-slot:prepend v-if="$store.state.auth.token !== null">
      <v-app-bar-nav-icon @click.stop="$emit('update')"></v-app-bar-nav-icon>
    </template>

    <v-spacer v-if="$store.state.auth.token != null"></v-spacer>
    <v-img
      v-if="$store.state.auth.token != null"
      class="ml-5 image-app-bar"
      src="../assets/stryker.png"
      @click="pushViews('home')"
      max-width="150"
    ></v-img>
    <v-spacer v-if="$store.state.auth.token != null"></v-spacer>
    <v-btn v-if="$store.state.auth.token != null" :icon="mdiWeb"></v-btn>
    <v-btn v-if="$store.state.auth.token != null" :icon="mdiMagnify"></v-btn>
    <v-btn
      v-if="$store.state.auth.token != null"
      :icon="mdiAccountOutline"
    ></v-btn>
    <v-btn v-if="$store.state.auth.token != null" :icon="mdiCartMinus"></v-btn>
    <v-btn
      v-if="$store.state.auth.token != null"
      :icon="mdiLogout"
      @click="logout"
    ></v-btn>
  </v-app-bar>
</template>

<script>
import {
  mdiWeb,
  mdiMagnify,
  mdiAccountOutline,
  mdiCartMinus,
  mdiLogout
} from "@mdi/js";

export default {
  name: "TopBar",
  data() {
    return {
      mdiWeb,
      mdiMagnify,
      mdiAccountOutline,
      mdiCartMinus,
      mdiLogout,
      menu: false
    };
  },
  methods: {
    logout() {
      this.$store.commit("auth/clearAuthData");
      this.$router.push({ name: "login" });
    },
    pushViews(view) {
      if (this.$route.name != view) {
        this.$router.push({ name: view });
      }
    }
  }
};
</script>

<style>
.image-app-bar {
  text-align: center;
}

div.v-toolbar__content {
  align-items: center !important;
}
.app-bar-custom {
  position: relative;
  overflow: visible;
}

.app-bar-custom::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background: -webkit-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions de Chrome et Safari */
  background: -moz-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour Firefox */
  background: -o-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions d'Opera */
  background: linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les navigateurs modernes */
}
</style>

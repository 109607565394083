<template>
  <v-container class="container-view-second" fluid>
    <v-row>
      <v-col cols="8" class="pr-0">
        <v-row>
          <v-col cols="5" class="pr-0">
            <v-img src="../assets/home_client.png" height="100%" cover> </v-img>
          </v-col>

          <v-col cols="7" class="pl-0 pr-5">
            <div class="container-frise-second">
              <v-img
                src="../assets/frise.png"
                class="frise-img-second"
                height="99%"
                cover
              >
                <h1 class="welcome-client-second">
                  Bienvenue sur votre espace Service One Stryker
                </h1>
                <div class="recap-div-second">
                  CHU Toulouse ( FRC000TEST ) Status 3 -
                  <span class="color-point-second"> 4 524 points restants</span>
                </div>
              </v-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-img src="@/assets/pub.png" height="100%" cover> </v-img>
      </v-col>
    </v-row>

    <v-row class="pb-8 pt-0">
      <v-col cols="12">
        <v-card
          class="mx-auto text-center pl-0 pr-0"
          color="white"
          max-width="99%"
          dark
          elevation="6"
        >
          <v-card-title class="title-background-second text-left">
            Quelques chiffres
          </v-card-title>
          <v-card-text class="pl-0 pr-0 pb-0">
            <data-component></data-component>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import DataComponent from "../components/DataComponent.vue";

import {
  mdiStarOutline,
  mdiPackageVariant,
  mdiAccountBoxOutline,
  mdiCogOutline,
  mdiForumOutline,
  mdiDatabaseCog
} from "@mdi/js";

export default defineComponent({
  name: "HomeView",
  components: {
    DataComponent
  },
  data() {
    return {
      mdiStarOutline,
      mdiPackageVariant,
      mdiAccountBoxOutline,
      mdiCogOutline,
      mdiForumOutline,
      value: [423, 446, 675, 510, 590, 610, 760, 400, 850, 900, 1200, 548, 154],
      items: [
        { text: "Utiliser mes points", icon: mdiStarOutline, view: "points" },
        {
          text: "Mes commandes One Stryker",
          icon: mdiPackageVariant,
          view: "stryker-one-commands"
        },
        {
          text: "Mes commandes Stryker",
          icon: mdiPackageVariant,
          view: "stryker-commands"
        },
        { text: "Mon contrat", icon: mdiAccountBoxOutline, view: "contrat" },
        {
          text: "Service One Stryker",
          icon: mdiCogOutline,
          view: "service-one-stryker"
        },
        { text: "Contact", icon: mdiForumOutline, view: "contact" },
        { text: "Administration", icon: mdiDatabaseCog, view: "admin" }
      ]
    };
  },
  methods: {
    pushView(view) {
      if (this.$route.name != view) {
        this.$router.push({ name: view });
      }
    }
  }
});
</script>

<style>
.recap-div-second {
  position: absolute;
  bottom: 20%; /* Place l'élément au bas de l'image */
  left: 0; /* Optionnel : aligne l'élément à gauche */
  right: 0; /* Optionnel : étend l'élément sur toute la largeur */
  text-align: left; /* Centrer le texte horizontalement */
}
.text-title-stats-second {
  color: #535756;
}

.color-point-second {
  color: #ffb500;
}

.container-view-second {
  margin-top: 30px;
}

.title-background-second {
  background-color: #535756;
  color: white;
}

.container-frise-second {
  height: 100%; /* Hauteur de ta div principale, à ajuster */
  background-color: #ffffff; /* Couleur de fond de ta div principale, à ajuster */
}

.frise-img-second {
  margin-top: 3px;
  position: relative;
}

.welcome-client-second {
  color: white;
  position: absolute;
  top: 40%;
  left: 0;
}
</style>

export default {
  auth: {
    clientId: process.env.VUE_APP_CLIENT,
    authority: "https://login.microsoftonline.com/"+process.env.VUE_APP_TENANT+"/v2.0",
    Audience: process.env.VUE_APP_AUDIENCE,
    redirectUri: process.env.VUE_APP_CALLBACK_URL,
    postLogoutRedirectUri: '/'

  },
  cache: {
    cacheLocation: "sessionStorage",
    temporaryCacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};

<template>
  <v-container class="container-view" fluid>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h5 font-weight-medium">CHU Toulouse ( FRC000TEST )</div>
    </v-row>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h6 font-weight-medium">
        Status 3 - <span class="color-point"> 14 030 points</span>
      </div>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card elevation="6">
          <v-card-title class="title-background">
            Mes dernières commandes
          </v-card-title>
          <v-card-text>
            <v-data-table
              show-expand
              item-value="invoice_number"
              :items="commandes"
              :headers="headers"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    <v-sheet elevation="9">
                      <v-table>
                        <thead>
                          <tr class="grey lighten-4">
                            <th>Numéro de commandes</th>
                            <th>Référence</th>
                            <th>Description</th>
                            <th>Prix</th>
                            <th>Quantité</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(order, index) in item.orders"
                            :key="index"
                            class="grey lighten-4"
                          >
                            <td>
                              {{ order.order_number }}
                            </td>
                            <td>
                              {{ order.products[0].reference }}
                            </td>
                            <td>
                              {{ order.products[0].description }}
                            </td>
                            <td>
                              {{ order.products[0].sales_amount }}
                            </td>
                            <td>
                              {{ order.products[0].quantity }}
                            </td>
                          </tr>
                        </tbody>
                      </v-table>
                    </v-sheet>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0">
        <div id="chart">
          <apexchart
            class="apex-chart"
            type="donut"
            width="500"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StrykerCommands",
  data() {
    return {
      headers: [
        {
          title: "Date de commande",
          align: "start",
          sortable: false,
          key: "date"
        },
        { title: "Numéro de facture", align: "start", key: "invoice_number" },
        { title: "Total", align: "start", key: "total" },
        { title: "Points", align: "start", key: "pts" }
      ],
      commandes: [
        {
          date: "20/08/2024",
          invoice_number: "4518251",
          total: "1700",
          pts: "1700",
          orders: [
            {
              order_number: "3010792784",
              products: [
                {
                  reference: "0028157000",
                  description: "RETAINING RING",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            },
            {
              order_number: "3010792783",
              products: [
                {
                  reference: "0081319000",
                  description: "BALL BEARING",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            },
            {
              order_number: "3010792782",
              products: [
                {
                  reference: "5100400115",
                  description: "SPACER WASHER",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            },
            {
              order_number: "3010792781",
              products: [
                {
                  reference: "5400400440",
                  description: "MID MOTOR CARTRIDGE",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            },
            {
              order_number: "3010792780",
              products: [
                {
                  reference: "700000775590",
                  description: "TPX SAGITTAL HEAD ASSEMBLY",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            },
            {
              order_number: "3010792779",
              products: [
                {
                  reference: "0038352000",
                  description: "COMP SPRING .240 O.D. .022WIRE",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            },
            {
              order_number: "3010792778",
              products: [
                {
                  reference: "LABOR-INST",
                  description: "LABOR CHARGES INSTRUMENTS",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            },
            {
              order_number: "3010792777",
              products: [
                {
                  reference: "FFTPX-OI",
                  description: "FLAT FEE TPX",
                  sales_amount: "1700,00",
                  quantity: "1"
                }
              ]
            }
          ]
        },
        {
          date: "20/09/2024",
          invoice_number: "Hotel",
          total: "1000",
          pts: "1000",
          orders: [
            {
              order_number: "3010792784",
              products: [
                {
                  reference: "0028157000",
                  description: "RETAINING RING",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            }
          ]
        },
        {
          date: "21/09/2024",
          invoice_number: "India",
          total: "258",
          pts: "258",
          orders: [
            {
              order_number: "3010792784",
              products: [
                {
                  reference: "0028157000",
                  description: "RETAINING RING",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            }
          ]
        },
        {
          date: "22/09/2024",
          invoice_number: "Juliett",
          total: "9518",
          pts: "9518",
          orders: [
            {
              order_number: "3010792784",
              products: [
                {
                  reference: "0028157000",
                  description: "RETAINING RING",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            }
          ]
        },
        {
          date: "23/09/2024",
          invoice_number: "Kilo",
          total: "7519",
          pts: "7519",
          orders: [
            {
              order_number: "3010792784",
              products: [
                {
                  reference: "0028157000",
                  description: "RETAINING RING",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            }
          ]
        },
        {
          date: "24/09/2024",
          invoice_number: "Lima",
          total: "685",
          pts: "685",
          orders: [
            {
              order_number: "3010792784",
              products: [
                {
                  reference: "0028157000",
                  description: "RETAINING RING",
                  sales_amount: "0,00",
                  quantity: "1"
                }
              ]
            }
          ]
        }
      ],
      series: [
        540150, 300000, 150000, 50000, 10000, 10000, 10000, 9000, 8000, 7000,
        6000, 5000
      ],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: [
          "Knees",
          "HPS",
          "Upper extremities",
          "OI",
          "Foot and ankle",
          "Trauma",
          "ST",
          "Neurosurgical",
          "IVS",
          "CMP",
          "Sports medicine",
          "ENT"
        ],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 500
        }
      }
    };
  },
  methods: {}
};
</script>

<style scoped>
.apex-chart {
  padding-top: 50px;
}
</style>

<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="desserts"
      v-model:search="search"
      :sort-by="[{ key: 'calories', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            label="Rechercher"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ props }">
              <v-btn class="mb-2" color="#ffb300" dark v-bind="props">
                Nouvel Utilisateur
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.lastname"
                        label="Nom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.firstname"
                        label="Prenom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.address"
                        label="Adresse"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        v-model="editedItem.role"
                        label="Rôle"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="close">
                  Retour
                </v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="save">
                  Sauvegarder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Voulez vous supprimer ce client ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="closeDelete"
                >
                  Retour
                </v-btn>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                >
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.statut="{ item }">
        <v-chip color="#ffb300" dark>
          {{ item.statut }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify } from "@mdi/js";

export default {
  name: "UserAdmin",
  data() {
    return {
      search: "",
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          title: "Email",
          align: "start",
          sortable: false,
          key: "email"
        },
        { title: "Nom", key: "lastname" },
        { title: "Prenom", key: "firstname" },
        { title: "Adresse", key: "address" },
        { title: "Role", key: "role" },
        { title: "Actions", key: "actions", sortable: false }
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        email: "",
        firstname: "",
        lastname: "",
        address: "",
        role: ""
      },
      defaultItem: {
        email: "",
        firstname: "",
        lastname: "",
        address: "",
        role: ""
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouvel Uilitsateur"
        : "Modification d'un utilisateur";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      const randomInt = (min, max) =>
        Math.floor(Math.random() * (max - min + 1)) + min;
      const randomEmail = () => `user${randomInt(1000, 9999)}@example.com`;
      const randomFirstname = () => {
        const names = [
          "Alice",
          "Bob",
          "Charlie",
          "David",
          "Eve",
          "Frank",
          "Grace",
          "Hannah",
          "Ivy",
          "Jack"
        ];
        return names[randomInt(0, names.length - 1)];
      };
      const randomLastname = () => {
        const lastnames = [
          "Smith",
          "Johnson",
          "Williams",
          "Jones",
          "Brown",
          "Davis",
          "Miller",
          "Wilson",
          "Moore",
          "Taylor"
        ];
        return lastnames[randomInt(0, lastnames.length - 1)];
      };
      const randomAddress = () => `${randomInt(1, 1000)} Main St`;

      this.desserts = Array.from({ length: 10 }, () => ({
        email: randomEmail(),
        firstname: randomFirstname(),
        lastname: randomLastname(),
        address: randomAddress(),
        role: "Administrateur"
      }));
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>

import axios from "axios";

export default {
  get() {
    return axios.get("/api/users");
  },
  currentUser() {
    return axios.get("/api/users/user");
  },
  store(user) {
    return axios.post("/api/users", user);
  },
  delete(id) {
    return axios.delete(`/api/users/${id}`);
  },
  update(user) {
    return axios.put(`/api/users/${user.id}`, user);
  }
};

import users from "@/api/users";

export default {
  namespaced: true,
  state: {
    items: [],
    user: null,
    selectedItems: null,
    editedId: -1,
    editedItem: {
      name: "",
      email: "",
      password: "",
      role: ""
    },
    defaultItem: {
      name: "",
      email: "",
      password: "",
      role: ""
    }
  },
  getters: {},
  actions: {
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        users
          .get()
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCurrentUser({ commit }) {
      return new Promise((resolve, reject) => {
        users
          .currentUser()
          .then((response) => {
            commit("setUser", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      state.editedItem = { ...item };
    },
    setEditedId(state, id) {
      state.editedId = id;
    },
    setDefaultItem(state) {
      state.editedItem = { ...state.defaultItem };
    }
  }
};

<template>
  <div id="chart">
    <apexchart
      :class="{ apexChart: isInCarousel }"
      type="donut"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "SecondCarouselItem",
  props: {
    isInCarousel: {
      type: Boolean,
      default: true
    },
    isSplited: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      series: [
        540150, 300000, 150000, 50000, 10000, 10000, 10000, 9000, 8000, 7000,
        6000, 5000
      ],
      chartOptions: {
        chart: {
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: [
          "Knees",
          "HPS",
          "Upper extremities",
          "OI",
          "Foot and ankle",
          "Trauma",
          "ST",
          "Neurosurgical",
          "IVS",
          "CMP",
          "Sports medicine",
          "ENT"
        ],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        title: {
          text: this.isSplited ? "" : "CA 2024 par sous-franchise"
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 500
        }
      }
    };
  }
};
</script>

<style scoped>
.apexChart {
  padding-left: 100px;
  padding-top: 25px;
}
</style>

<template>
  <v-row class="">
    <v-col cols="4" class="d-flex justify-center">
      <v-sheet
        :elevation="9"
        :class="{
          'not-focused': activeImage !== 0,
          'align-self-center': activeImage !== 0
        }"
        :height="getHeight(0)"
        @click="selectImage(0)"
        width="100%"
        max-width="400"
      >
        <div class="d-flex flex-column" style="height: 100%">
          <h2 class="text-h4 font-weight-black text-center pt-5 title-z-index">
            Pre-op
          </h2>
          <div class="flex-grow-1 d-flex position-relative">
            <v-img src="@/assets/pre-op.jpg" class="mx-5 my-5" cover></v-img>
          </div>

          <div
            class="d-flex align-center justify-center flex-wrap text-center pb-5"
          >
            <v-btn class="color-button" @click="selectConfiguration('pre-op')">
              En savoir plus
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-col>
    <v-col cols="4" class="d-flex justify-center">
      <v-sheet
        :elevation="9"
        :class="{
          'not-focused': activeImage !== 1,
          'align-self-center': activeImage !== 1
        }"
        :height="getHeight(1)"
        @click="selectImage(1)"
        width="100%"
        max-width="400"
      >
        <div class="d-flex flex-column" style="height: 100%">
          <h2 class="text-h4 font-weight-black text-center pt-5 title-z-index">
            Per-op
          </h2>
          <div class="flex-grow-1 d-flex position-relative">
            <v-img
              src="@/assets/per-op.jpg"
              class="mx-5 my-5"
              :class="{
                'image-height': activeImage !== 1
              }"
              cover
            ></v-img>
          </div>

          <div
            class="d-flex align-center justify-center flex-wrap text-center pb-5"
          >
            <v-btn class="color-button" @click="selectConfiguration('per-op')">
              En savoir plus
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-col>
    <v-col cols="4" class="d-flex justify-center">
      <v-sheet
        :elevation="9"
        :class="{
          'not-focused': activeImage !== 2,
          'align-self-center': activeImage !== 2
        }"
        class="sheet-index"
        :height="getHeight(2)"
        @click="selectImage(2)"
        width="100%"
        max-width="400"
      >
        <div class="d-flex flex-column" style="height: 100%">
          <h2 class="text-h4 font-weight-black text-center pt-5 title-z-index">
            Post-op
          </h2>
          <div class="flex-grow-1 d-flex position-relative">
            <v-img src="@/assets/post-op.jpg" class="mx-5 my-5" cover></v-img>
          </div>

          <div
            class="d-flex align-center justify-center flex-wrap text-center pb-5"
          >
            <v-btn class="color-button" @click="selectConfiguration('post-op')">
              En savoir plus
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";

export default {
  name: "OpSelection",
  data() {
    return {
      mdiChevronRight,
      mdiChevronLeft,
      activeImage: 1 // Index de l'image active
    };
  },
  methods: {
    selectConfiguration(configuration) {
      this.$emit("selectConfiguration", configuration);
    },
    prevImage() {
      if (this.activeImage > 0) {
        this.activeImage--;
      }
    },
    nextImage() {
      if (this.activeImage < 2) {
        this.activeImage++;
      }
    },
    selectImage(index) {
      this.activeImage = index;
    },
    getHeight(index) {
      return this.activeImage === index ? 600 : 500;
    }
  }
};
</script>

<style scoped>
.image-height {
  max-height: 350px !important;
}

.title-z-index {
  z-index: 2;
}

.not-focused {
  position: relative;
  overflow: hidden;
}

.not-focused::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5); /* Overlay gris avec opacité */
  z-index: 1;
}

.sheet-index {
  z-index: 3;
}

.left-arrow {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
}

.right-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
}

.title-z-index {
  z-index: 2;
}
</style>

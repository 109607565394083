import auth from "@/api/auth";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("authToken") || null,
    loading: false,
    user: null
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getUser: (state) => state.user
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        auth
          .login(data)
          .then((response) => {
            commit("setToken", response.data.token);
            commit("setLoading", false);
            resolve(response.data);
          })
          .catch((error) => {
            commit("setLoading", false);
            reject(error);
          });
      });
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("authToken", token);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    clearAuthData(state) {
      state.token = null;
      state.user = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
    },
    setLoading(state, loading) {
      state.loading = loading;
    }
  }
};

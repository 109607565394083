<template>
  <v-container class="container-view" fluid>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h5 font-weight-medium">CHU Toulouse ( FRC000TEST )</div>
    </v-row>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h6 font-weight-medium">
        Status 3 - <span class="color-point"> 14 030 points</span>
      </div>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card height="52vh" elevation="6">
          <v-card-title class="title-background">
            Mes dernières commandes One Stryker
          </v-card-title>
          <v-card-text>
            <v-data-table show-expand item-value="nom" :items="commandes">
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    More info about {{ item.nom }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0">
        <div id="chart">
          <apexchart
            class="apex-chart"
            type="donut"
            width="500"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StrykerOneCommands",
  data() {
    return {
      commandes: [
        {
          date: "19/09/2024",
          nom: "Golf",
          total: "500",
          pts: "500"
        },
        {
          date: "20/09/2024",
          nom: "Hotel",
          total: "1000",
          pts: "1000"
        },
        {
          date: "21/09/2024",
          nom: "India",
          total: "258",
          pts: "258"
        },
        {
          date: "22/09/2024",
          nom: "Juliett",
          total: "9518",
          pts: "9518"
        },
        {
          date: "23/09/2024",
          nom: "Kilo",
          total: "7519",
          pts: "7519"
        },
        {
          date: "24/09/2024",
          nom: "Lima",
          total: "685",
          pts: "685"
        }
      ],
      series: [
        540150, 300000, 150000, 50000, 10000, 10000, 10000, 9000, 8000, 7000,
        6000, 5000
      ],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: [
          "Knees",
          "HPS",
          "Upper extremities",
          "OI",
          "Foot and ankle",
          "Trauma",
          "ST",
          "Neurosurgical",
          "IVS",
          "CMP",
          "Sports medicine",
          "ENT"
        ],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 500
        }
      }
    };
  },
  methods: {}
};
</script>

<style scoped>
.apex-chart {
  padding-top: 50px;
}
</style>

import { createStore } from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";

export default createStore({
  state: {
    snackbar: {
      model: false,
      text: "",
      color: ""
    }
  },
  modules: {
    auth,
    users
  },
  getters: {},
  actions: {
    showSnackbar({ commit }, payload) {
      commit("showSnackbar", payload);
    },
    hideSnackbar({ commit }) {
      commit("hideSnackbar");
    }
  },
  mutations: {
    showSnackbar(state, { message, color }) {
      state.snackbar.model = true;
      state.snackbar.text = message;
      state.snackbar.color = color;
    },
    hideSnackbar(state) {
      state.snackbar.model = false;
      state.snackbar.text = "";
      state.snackbar.color = "";
    },
    setSnackbar(state, value) {
      state.snackbar = value;
    }
  }
});
